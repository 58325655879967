<template>
  <div>
    <div class="mb-6 flex justify-start items-center">
      <router-link to="/order" class="flex justify-start items-center">
        <ArrowForward direction="left" width="25" height="25" />
      </router-link>
      <h1 class="text-3xl font-bold ml-3 hover:no-underline">Detail Order</h1>
    </div>

    <div v-if="stillLoading" class="loading-page">
      <vue-simple-spinner size="large" message="Loading..."></vue-simple-spinner>
    </div>
    <div v-else>
      <div class="text-base font-semibold text-left mb-2">Order Summary</div>
      <div class="bg-white shadow-small border-grey rounded-lg card mb-4">
        <div class="flex items-center text-left px-4 py-3 bg-grey-header">
          <div class="text-base font-bold w-1/4">Order ID</div>
          <div class="text-base font-bold w-1/5">Order Date</div>
          <div class="text-base font-bold w-1/12">Qty</div>
          <div class="text-base font-bold w-1/5">Amount</div>
          <div class="text-base font-bold w-1/5">Status</div>
        </div>
        <div class="flex items-center py-2 text-left px-4">
          <div class="text-base w-1/4">
            {{ orderId }}
          </div>
          <div class="text-base w-1/5">
            {{ orderDate }}
          </div>
          <div class="text-base w-1/12">
            {{ qty }}
          </div>
          <div class="text-base w-1/5">
            {{ totalPrice }}
          </div>
          <div class="text-base w-fit p-2 font-bold rounded-lg" :style="customStyle(status)">
            {{ status }}
          </div>
        </div>
      </div>
      <div class="w-4/5">
        <div class="text-base font-semibold text-left mb-2">Status</div>
        <div class="bg-white shadow-small border-grey rounded-lg card mb-4">
          <div class="p-4" v-for="(data, idx) in history" :key="idx">
            <div class="flex items-center text-left">
              <div class="rounded-full p-2 mr-4" :class="idx === 0 ? 'bg-yellow-400' : 'bg-neutral'"></div>
              <div class="text-base font-semibold" :class="idx === 0 ? 'text-yellow-400' : 'text-neutral'">
                {{ data.label }}
              </div>
            </div>
            <div class="flex items-center text-left">
              <div class="rounded-full p-2 bg-white mr-4"></div>
              <div class="text-base">
                {{ data.date }}
              </div>
            </div>
          </div>
        </div>

        <div class="text-base font-semibold text-left mb-2">Product</div>
        <div class="bg-white shadow-small border-grey rounded-lg card mb-4">
          <div class="bg-white border-grey-b rounded-lg card">
            <div class="flex items-center text-left px-4 py-3 bg-grey-header">
              <div class="text-base font-bold w-1/2">Class</div>
              <div class="text-base font-bold w-1/3">Program Type</div>
              <div class="text-base font-bold w-1/5">Price</div>
            </div>
            <div class="flex items-center py-2 text-left px-4" v-for="(item, indexItem) in orderItems" :key="indexItem">
              <div class="text-base w-1/2">
                {{ item.name }}
              </div>
              <div class="text-base w-1/3">
                {{ item.type }}
              </div>
              <div class="text-base w-1/5">
                {{ item.price | currencyFormat }}
              </div>
            </div>
          </div>
        </div>

        <div class="text-base font-semibold text-left mb-2">Profile</div>
        <div class="bg-white shadow-small border-grey rounded-lg card mb-4">
          <div class="p-4 w-1/2">
            <TextField type="text" class="mb-3 bg-white" label="Nama" v-model="name" :disabledWhite="true" borderEnabled />
            <TextField type="text" class="mb-3" label="Email" v-model="email" borderEnabled :disabledWhite="true" />
            <TextField type="text" class="mb-2" label="No Telepon" v-if="phone" v-model="phone" borderEnabled :disabledWhite="true" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { currencyFormat } from '@/utils/currency'
import moment from 'moment'
export default {
  name: 'OrderDetails',
  mounted() {
    this.getData()
  },
  data: function () {
    return {
      stillLoading: true,
      name: '',
      email: '',
      phone: '',
      orderDate: '',
      qty: '',
      totalPrice: '',
      status: '',
      history: [],
      orderItems: []
    }
  },
  components: {
    ArrowForward: () => import(/* webpackChunkName: "icon" */ '@/components/Icons/ArrowForward'),
    TextField: () => import(/* webpackChunkName: "TextField" */ '@/components/Form/TextField')
  },
  filters: {
    currencyFormat
  },
  methods: {
    customStyle(status) {
      let result = ''
      switch (status) {
        case 'Sukses':
          result = 'background: #D5F6E3; color:#27AE60'
          break
        case 'Menunggu Pembayaran':
          result = 'background: #FFF6D6; color:#FFC400'
          break

        case 'Lanjutkan Pembayaran':
          result = 'background: #FFF6D6; color:#FFC400'
          break
        default:
          result = 'background: #FAD1D1; color:#EB5757'
          break
      }
      return result
    },
    translateStatus(status) {
      let result = { label: '', style: '' }
      switch (status) {
        case 'PAID':
          result.label = 'Sukses'
          result.style = 'background: #D5F6E3; color:#27AE60'
          break
        case 'PENDING':
          result.label = 'Menunggu Pembayaran'
          result.style = 'background: #FFF6D6; color:#FFC400'
          break

        case 'WAITING_FOR_PAYMENT':
          result.label = 'Lanjutkan Pembayaran'
          result.style = 'background: #FFF6D6; color:#FFC400'
          break
        default:
          result.label = 'Gagal'
          result.style = 'background: #FAD1D1; color:#EB5757'
          break
      }
      return result
    },
    getData() {
      // this.scrollToTop()
      this.$store
        .dispatch('order/GET_ORDER_DETAILS', {
          orderId: this.$route.params.orderId
        })
        .then(() => {
          this.email = this.orderDetailsData.userEmail
          this.orderDate = moment(`${this.orderDetailsData.createdDate}.000+0000`).format('DD MMM YYYY')
          this.qty = this.orderDetailsData.orderItems.length
          this.totalPrice = currencyFormat(this.orderDetailsData.totalPrice + this.orderDetailsData.totalDiscount)
          this.status = this.translateStatus(this.orderDetailsData.status).label
          const history = [
            {
              label: this.translateStatus(this.orderDetailsData.status).label,
              date: moment(`${this.orderDetailsData.transactionTime}.000+0000`).format('DD MMM YYYY HH:mm')
            },
            {
              label: 'Order dibuat',
              date: moment(`${this.orderDetailsData.createdDate}.000+0000`).format('DD MMM YYYY HH:mm')
            }
          ]
          this.history = history
          this.orderItems = this.orderDetailsData.orderItems
          this.$store.dispatch('order/GET_USER_BY_USER_ID', { userId: this.orderDetailsData.userId }).then((res) => {
            this.name = res.name,
            this.phone = res.mobilePhone
          })
          this.stillLoading = false
        })
    }
  },
  computed: {
    orderDetailsData() {
      return this.$store.getters['order/orderDetails'] ? this.$store.getters['order/orderDetails'] : []
    },
    orderId() {
      return this.$route.params.orderId
    }
  },

  watch: {
    $route(to, from) {
      this.showProgress = true
      this.initData()
    }
  }
}
</script>
