var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "mb-6 flex justify-start items-center"
  }, [_c('router-link', {
    staticClass: "flex justify-start items-center",
    attrs: {
      "to": "/order"
    }
  }, [_c('ArrowForward', {
    attrs: {
      "direction": "left",
      "width": "25",
      "height": "25"
    }
  })], 1), _c('h1', {
    staticClass: "text-3xl font-bold ml-3 hover:no-underline"
  }, [_vm._v("Detail Order")])], 1), _vm.stillLoading ? _c('div', {
    staticClass: "loading-page"
  }, [_c('vue-simple-spinner', {
    attrs: {
      "size": "large",
      "message": "Loading..."
    }
  })], 1) : _c('div', [_c('div', {
    staticClass: "text-base font-semibold text-left mb-2"
  }, [_vm._v("Order Summary")]), _c('div', {
    staticClass: "bg-white shadow-small border-grey rounded-lg card mb-4"
  }, [_vm._m(0), _c('div', {
    staticClass: "flex items-center py-2 text-left px-4"
  }, [_c('div', {
    staticClass: "text-base w-1/4"
  }, [_vm._v(" " + _vm._s(_vm.orderId) + " ")]), _c('div', {
    staticClass: "text-base w-1/5"
  }, [_vm._v(" " + _vm._s(_vm.orderDate) + " ")]), _c('div', {
    staticClass: "text-base w-1/12"
  }, [_vm._v(" " + _vm._s(_vm.qty) + " ")]), _c('div', {
    staticClass: "text-base w-1/5"
  }, [_vm._v(" " + _vm._s(_vm.totalPrice) + " ")]), _c('div', {
    staticClass: "text-base w-fit p-2 font-bold rounded-lg",
    style: _vm.customStyle(_vm.status)
  }, [_vm._v(" " + _vm._s(_vm.status) + " ")])])]), _c('div', {
    staticClass: "w-4/5"
  }, [_c('div', {
    staticClass: "text-base font-semibold text-left mb-2"
  }, [_vm._v("Status")]), _c('div', {
    staticClass: "bg-white shadow-small border-grey rounded-lg card mb-4"
  }, _vm._l(_vm.history, function (data, idx) {
    return _c('div', {
      key: idx,
      staticClass: "p-4"
    }, [_c('div', {
      staticClass: "flex items-center text-left"
    }, [_c('div', {
      staticClass: "rounded-full p-2 mr-4",
      class: idx === 0 ? 'bg-yellow-400' : 'bg-neutral'
    }), _c('div', {
      staticClass: "text-base font-semibold",
      class: idx === 0 ? 'text-yellow-400' : 'text-neutral'
    }, [_vm._v(" " + _vm._s(data.label) + " ")])]), _c('div', {
      staticClass: "flex items-center text-left"
    }, [_c('div', {
      staticClass: "rounded-full p-2 bg-white mr-4"
    }), _c('div', {
      staticClass: "text-base"
    }, [_vm._v(" " + _vm._s(data.date) + " ")])])]);
  }), 0), _c('div', {
    staticClass: "text-base font-semibold text-left mb-2"
  }, [_vm._v("Product")]), _c('div', {
    staticClass: "bg-white shadow-small border-grey rounded-lg card mb-4"
  }, [_c('div', {
    staticClass: "bg-white border-grey-b rounded-lg card"
  }, [_vm._m(1), _vm._l(_vm.orderItems, function (item, indexItem) {
    return _c('div', {
      key: indexItem,
      staticClass: "flex items-center py-2 text-left px-4"
    }, [_c('div', {
      staticClass: "text-base w-1/2"
    }, [_vm._v(" " + _vm._s(item.name) + " ")]), _c('div', {
      staticClass: "text-base w-1/3"
    }, [_vm._v(" " + _vm._s(item.type) + " ")]), _c('div', {
      staticClass: "text-base w-1/5"
    }, [_vm._v(" " + _vm._s(_vm._f("currencyFormat")(item.price)) + " ")])]);
  })], 2)]), _c('div', {
    staticClass: "text-base font-semibold text-left mb-2"
  }, [_vm._v("Profile")]), _c('div', {
    staticClass: "bg-white shadow-small border-grey rounded-lg card mb-4"
  }, [_c('div', {
    staticClass: "p-4 w-1/2"
  }, [_c('TextField', {
    staticClass: "mb-3 bg-white",
    attrs: {
      "type": "text",
      "label": "Nama",
      "disabledWhite": true,
      "borderEnabled": ""
    },
    model: {
      value: _vm.name,
      callback: function callback($$v) {
        _vm.name = $$v;
      },
      expression: "name"
    }
  }), _c('TextField', {
    staticClass: "mb-3",
    attrs: {
      "type": "text",
      "label": "Email",
      "borderEnabled": "",
      "disabledWhite": true
    },
    model: {
      value: _vm.email,
      callback: function callback($$v) {
        _vm.email = $$v;
      },
      expression: "email"
    }
  }), _vm.phone ? _c('TextField', {
    staticClass: "mb-2",
    attrs: {
      "type": "text",
      "label": "No Telepon",
      "borderEnabled": "",
      "disabledWhite": true
    },
    model: {
      value: _vm.phone,
      callback: function callback($$v) {
        _vm.phone = $$v;
      },
      expression: "phone"
    }
  }) : _vm._e()], 1)])])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex items-center text-left px-4 py-3 bg-grey-header"
  }, [_c('div', {
    staticClass: "text-base font-bold w-1/4"
  }, [_vm._v("Order ID")]), _c('div', {
    staticClass: "text-base font-bold w-1/5"
  }, [_vm._v("Order Date")]), _c('div', {
    staticClass: "text-base font-bold w-1/12"
  }, [_vm._v("Qty")]), _c('div', {
    staticClass: "text-base font-bold w-1/5"
  }, [_vm._v("Amount")]), _c('div', {
    staticClass: "text-base font-bold w-1/5"
  }, [_vm._v("Status")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex items-center text-left px-4 py-3 bg-grey-header"
  }, [_c('div', {
    staticClass: "text-base font-bold w-1/2"
  }, [_vm._v("Class")]), _c('div', {
    staticClass: "text-base font-bold w-1/3"
  }, [_vm._v("Program Type")]), _c('div', {
    staticClass: "text-base font-bold w-1/5"
  }, [_vm._v("Price")])]);
}]

export { render, staticRenderFns }